<template>
  <div id="app">
    <page-header
      v-if="$route.meta.withoutHeader !== true"
      :class="{
        v: headerFooterShow
      }"
    ></page-header>
    <router-view :key="$route.name" ref="nmb" />
    <page-footer
      v-if="$route.meta.withoutFooter !== true"
      :class="{
        v: headerFooterShow
      }"
    ></page-footer>
    <div id="card1" ref="card1"></div>
    <div id="card2" ref="card2"></div>
    <transition
      @after-enter="afterEnter"
      @before-leave="beforeLeave"
      @leave="leave"
      @after-leave="afterLeave"
    >
      <div class="page__sub-menu__container" v-if="subMenusOpen">
        <div class="sub-menu__wrapper">
          <router-link
            class="sub__menu__logo"
            tag="div"
            :to="{
              path: '/'
            }"
          ></router-link>
          <div class="sub__line">
            <div class="line"></div>
          </div>
          <div class="sub__menus">
            <div class="sub__menus__inner">
              <div
                class="menu__item"
                :class="{
                  pcHover: !$bowser.mobile
                }"
                v-for="(item, index) in menus"
                :key="index"
              >
                <router-link class="item__link" :to="item.link">
                  <p class="link__en">{{ item.title.en }}</p>
                  <p class="link__cn" v-if="!$bowser.mobile">{{ item.title.cn }}</p>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import GSAP from 'gsap';
import events from '@/utils/eventBus';
import pageHeader from '@/views/common/header.vue';
import pageFooter from '@/views/common/footer.vue';

const tl = GSAP.timeline();

export default {
  name: 'core__app',
  components: {
    pageHeader,
    pageFooter,
  },
  data() {
    return {
      headerFooterShow: false,
      menus: [],
    };
  },
  computed: {
    ...mapState(['subMenusOpen', 'routerSwitchAnimating']),
  },
  watch: {
    $route() {
      this.init();
      this.headerFooterShow = false;
    },
    subMenusOpen(val) {
      if (val) {
        document.body.classList.add('--menu-open');
      } else {
        document.body.classList.remove('--menu-open');
      }
    },
    headerFooterShow(val) {
      console.log(val);
    },
  },
  created() {
    const that = this;
    that.getProductTypes().then((types) => {
      that.menus = [
        {
          title: {
            en: 'about',
            cn: '关于',
          },
          link: '/about',
        },
        {
          title: {
            en: 'project',
            cn: '项目',
          },
          link: `/projects/${types['0'].id}`,
        },
        {
          title: {
            en: 'awards',
            cn: '奖项',
          },
          link: '/awards',
        },
        {
          title: {
            en: 'news',
            cn: '新闻',
          },
          link: '/news',
        },
        {
          title: {
            en: 'jobs',
            cn: '职位',
          },
          link: '/join',
        },
        {
          title: {
            en: 'contact',
            cn: '联系',
          },
          link: '/contact',
        },
      ];
    });
    that.getMembers();
  },
  mounted() {
    this.WEBSITE_ENTERED();
    events.$on('beforeRouterEach', this.onRouterChange);
    events.$on('pageMounted', () => {
      this.headerFooterShow = true;
    });
  },
  methods: {
    ...mapMutations(['WEBSITE_ENTERED', 'CLOSE_SUB_MENU', 'ANIMATION_START', 'ANIMATION_END']),
    ...mapActions(['getProductTypes', 'getMembers']),
    init() {
      this.$nextTick(() => {
        this.pageInit();
      });
    },
    onRouterChange(next) {
      const that = this;
      const n = that.$refs.card1;
      // const i = that.$refs.card2;
      if (!that.routerSwitchAnimating) {
        that.ANIMATION_START();
        that.CLOSE_SUB_MENU();
        tl.set([n], {
          xPercent: 100,
          display: 'block',
        });
        tl.to(n, {
          xPercent: 0,
          duration: 0.6,
          autoAlpha: 1,
          ease: 'power3.in',
          onComplete: () => {
            next();
          },
        });
        tl.to(n, {
          xPercent: -100,
          duration: 0.6,
          delay: 0.3,
          ease: 'power3.in',
          onComplete: () => {
            n.style.cssText = 'dispay:none';
            that.ANIMATION_END();
          },
        });
        // tl.to(i, {
        //   xPercent: 0,
        //   duration: 0.6,
        //   delay: 0.3,
        //   ease: 'power3.in',
        //   onComplete: () => {
        //     // window.location = t;
        //     n.style.cssText = 'dispay:none';
        //     setTimeout(() => {
        //       i.style.cssText = 'dispay:none';
        //     }, 100);
        //     that.ANIMATION_END();
        //     next();
        //   },
        // });
      }
    },
    afterEnter(el) {
      el.classList.add('show');
    },
    beforeLeave(el) {
      el.classList.add('close');
    },
    leave(el, done) {
      setTimeout(() => {
        done();
      }, 1000);
    },
    afterLeave(el) {
      el.classList.remove('show', 'close');
    },
  },
};
</script>

<style lang="scss">
#card1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #c4c4c4;
  opacity: 0.25;
  z-index: 999;
  display: none;
}
#card2 {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: #fff;
}
</style>
