<template>
  <div
    class="header__container"
    ref="headerWrapper"
    :class="{
      hidden:
        $route.name === 'router-about' ||
        $route.name === 'router-home' ||
        $route.name === 'router-home2',
      absolute: !(
        $route.name === 'router-about' ||
        $route.name === 'router-home' ||
        $route.name === 'router-home2' ||
        $route.name === 'router-contact'
      ),
      'special-award': !(
        $route.name === 'router-about' ||
        $route.name === 'router-home' ||
        $route.name === 'router-home2' ||
        $route.name === 'router-contact'
      ),
      showTop:
        subMenusOpen &&
        !(
          $route.name === 'router-about' ||
          $route.name === 'router-home' ||
          $route.name === 'router-home2'
        ),
      extendHeader: $route.name === 'router-awards-2',
      mobileContact: $route.name === 'router-contact',
      mobile: $bowser.mobile,
      aboutHeader: $route.name === 'router-about'
    }"
  >
    <div class="header__inner">
      <router-link
        tag="div"
        class="header__logo"
        ref="logo"
        :to="{
          path: '/'
        }"
        :class="{
          open: subMenusOpen
        }"
      ></router-link>
      <div
        class="header__menu"
        :class="{
          open: subMenusOpen
        }"
        @click="statusChange"
      >
        <label class="menu__text">
          <div>{{ subMenusOpen ? "close" : "menu" }}</div>
        </label>
        <div class="menu__inner"><i class="line"></i><i class="line"></i><i class="line"></i></div>
      </div>
    </div>
    <div class="extendText" v-if="$route.name === 'router-awards-2'">
      <div
        class="innerText"
        :class="{
          show: currentExtend === t
        }"
        v-for="(t, i) in extendTitles"
        :key="i"
      >
        <p>{{ t }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'c__header',
  computed: {
    ...mapState(['subMenusOpen']),
  },
  data() {
    return {
      currentExtend: null,
      extendTitles: null,
    };
  },
  watch: {
    $route(val) {
      const that = this;
      if (val.name === 'router-awards-2') {
        this.$nextTick(() => {
          window.addEventListener('scroll', this.onScroll);
          const yearTitles = document.querySelectorAll('#pageAwards2Wrapper .year__title');
          that.extendTitles = Array.from(yearTitles).map((item) => item.innerText);
        });
      }
    },
  },
  mounted() {},
  destroyed() {
    console.log('header destroyed');
  },
  methods: {
    ...mapMutations(['CLOSE_SUB_MENU', 'OPEN_SUB_MENU']),
    statusChange() {
      if (this.subMenusOpen) {
        this.CLOSE_SUB_MENU();
      } else {
        this.OPEN_SUB_MENU();
      }
    },
    onScroll() {
      const header = this.$refs.headerWrapper;
      const headerBcr = header.getBoundingClientRect();
      const awardContainer = document.querySelectorAll(
        '#pageAwards2Wrapper .awards-year__container',
      );
      if (awardContainer) {
        const currentContainer = Array.from(awardContainer).find((container) => {
          const cbcr = container.getBoundingClientRect();
          return cbcr.top < headerBcr.bottom && cbcr.bottom > headerBcr.bottom;
        });
        if (currentContainer) {
          const title = currentContainer.querySelector('.year__title').innerText;
          if (this.currentExtend !== title) {
            this.currentExtend = title;
            header.classList.add('award-mask');
          }
        }
        if (awardContainer['0'].getBoundingClientRect().top >= headerBcr.bottom) {
          this.currentExtend = null;
          header.classList.remove('award-mask');
        }
      }
    },
  },
};
</script>

<style></style>
