import Vue from 'vue';
import VueRouter from 'vue-router';
import bowser from 'bowser';
import store from '@/store';
import events from '@/utils/eventBus';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'router-home',
    component: () => import('@/views/demo1/home-2.vue'),
    meta: {
      withoutFooter: true,
    },
  },
  {
    path: '/home2',
    name: 'router-home2',
    component: () => import('@/views/demo1/home.vue'),
    meta: {
      withoutFooter: true,
    },
  },
  {
    path: '/about',
    name: 'router-about',
    component: () => import('@/views/demo1/about/index.vue'),
  },
  {
    path: '/member/:id',
    name: 'router-member',
    component: () => import('@/views/demo1/about/d1.vue'),
    meta: {
      withoutHeader: true,
      withoutFooter: !bowser.mobile,
    },
  },
  {
    path: '/news',
    name: 'router-news',
    component: () => import('@/views/demo1/news/index.vue'),
  },
  {
    path: '/news/:id',
    name: 'router-news-detail',
    component: () => import('@/views/demo1/news/detail.vue'),
  },
  {
    path: '/join',
    name: 'router-join',
    component: () => import('@/views/demo1/join.vue'),
  },
  {
    path: '/awards',
    name: 'router-awards',
    component: () => import('@/views/demo1/awards2.vue'),
  },
  {
    path: '/awards-2',
    name: 'router-awards-2',
    component: () => import('@/views/demo1/awards.vue'),
  },
  {
    path: '/projects/:typeID',
    name: 'router-project',
    component: () => import('@/views/demo1/project/index.vue'),
  },
  {
    path: '/projects/list',
    name: 'router-project-shape-list',
    component: () => import('@/views/demo1/project/list.vue'),
  },
  {
    path: '/project/:id',
    name: 'router-project-detail',
    component: () => import('@/views/demo1/project/detail.vue'),
    meta: {
      withoutFooter: !bowser.mobile,
      scrollBehavior: true,
    },
  },
  {
    path: '/contact',
    name: 'router-contact',
    component: () => import('@/views/demo1/contact.vue'),
    meta: {
      withoutFooter: true,
    },
  },
  {
    path: '/test',
    name: 'router-test',
    component: () => import('@/views/demo1/test.vue'),
    meta: {
      withoutHeader: true,
      withoutFooter: true,
    },
  },
  {
    path: '/automove',
    name: 'router-projects-auto-move',
    component: () => import('@/views/demo1/project/auto-move.vue'),
    meta: {
      withoutHeader: true,
      withoutFooter: true,
    },
  },
  {
    path: '/am2',
    name: 'router-projects-auto-move',
    component: () => import('@/views/demo1/project/am2.vue'),
    meta: {
      withoutHeader: true,
      withoutFooter: true,
    },
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  mode: process.env.NODE_ENV === 'production' ? 'history' : 'hash',
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(from.meta.scrollBehavior && savedPosition ? savedPosition : {
          x: 0,
          y: 0,
        });
      }, 500);
    });
  },
});

// let RFA;

router.beforeEach((to, from, next) => {
  // if (to.name === 'router-home' && document.querySelector('.header__container')) {
  //   document.querySelector('.header__container').classList.add('hidden');
  // }
  if (store.state.firstEnterWebSite) {
    next();
  } else {
    events.$emit('beforeRouterEach', (next));
  }
});

export default router;
