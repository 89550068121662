var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"headerWrapper",staticClass:"header__container",class:{
    hidden:
      _vm.$route.name === 'router-about' ||
      _vm.$route.name === 'router-home' ||
      _vm.$route.name === 'router-home2',
    absolute: !(
      _vm.$route.name === 'router-about' ||
      _vm.$route.name === 'router-home' ||
      _vm.$route.name === 'router-home2' ||
      _vm.$route.name === 'router-contact'
    ),
    'special-award': !(
      _vm.$route.name === 'router-about' ||
      _vm.$route.name === 'router-home' ||
      _vm.$route.name === 'router-home2' ||
      _vm.$route.name === 'router-contact'
    ),
    showTop:
      _vm.subMenusOpen &&
      !(
        _vm.$route.name === 'router-about' ||
        _vm.$route.name === 'router-home' ||
        _vm.$route.name === 'router-home2'
      ),
    extendHeader: _vm.$route.name === 'router-awards-2',
    mobileContact: _vm.$route.name === 'router-contact',
    mobile: _vm.$bowser.mobile,
    aboutHeader: _vm.$route.name === 'router-about'
  }},[_c('div',{staticClass:"header__inner"},[_c('router-link',{ref:"logo",staticClass:"header__logo",class:{
        open: _vm.subMenusOpen
      },attrs:{"tag":"div","to":{
        path: '/'
      }}}),_c('div',{staticClass:"header__menu",class:{
        open: _vm.subMenusOpen
      },on:{"click":_vm.statusChange}},[_c('label',{staticClass:"menu__text"},[_c('div',[_vm._v(_vm._s(_vm.subMenusOpen ? "close" : "menu"))])]),_vm._m(0)])],1),(_vm.$route.name === 'router-awards-2')?_c('div',{staticClass:"extendText"},_vm._l((_vm.extendTitles),function(t,i){return _c('div',{key:i,staticClass:"innerText",class:{
        show: _vm.currentExtend === t
      }},[_c('p',[_vm._v(_vm._s(t))])])}),0):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu__inner"},[_c('i',{staticClass:"line"}),_c('i',{staticClass:"line"}),_c('i',{staticClass:"line"})])}]

export { render, staticRenderFns }