<template>
  <div
    class="footer__container"
    :class="{
      '-news-detail': $route.name === 'router-news-detail'
    }"
  >
    <div class="footer__inner">
      <template v-if="$bowser.mobile">
        <p>© FACE Interior Design. 飞视设计 网站版权所有</p>
        <p>沪ICP备17010633号</p>
      </template>
      <template v-else>
        © FACE Interior Design. 飞视设计 网站版权所有 | 沪ICP备17010633号 |
        <a href="http://www.gooob.cn">Web Design: Gooobrand</a>
      </template>
    </div>
    <div
      class="page-to-up"
      id="pageToUp"
      @click="goTop"
      v-if="$route.name !== 'router-member' && !$bowser.mobile"
    >
      <div class="up-icon"></div>
      <div class="up-text">top</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'c__footer',
  methods: {
    goTop() {
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style></style>
