var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[(_vm.$route.meta.withoutHeader !== true)?_c('page-header',{class:{
      v: _vm.headerFooterShow
    }}):_vm._e(),_c('router-view',{key:_vm.$route.name,ref:"nmb"}),(_vm.$route.meta.withoutFooter !== true)?_c('page-footer',{class:{
      v: _vm.headerFooterShow
    }}):_vm._e(),_c('div',{ref:"card1",attrs:{"id":"card1"}}),_c('div',{ref:"card2",attrs:{"id":"card2"}}),_c('transition',{on:{"after-enter":_vm.afterEnter,"before-leave":_vm.beforeLeave,"leave":_vm.leave,"after-leave":_vm.afterLeave}},[(_vm.subMenusOpen)?_c('div',{staticClass:"page__sub-menu__container"},[_c('div',{staticClass:"sub-menu__wrapper"},[_c('router-link',{staticClass:"sub__menu__logo",attrs:{"tag":"div","to":{
            path: '/'
          }}}),_c('div',{staticClass:"sub__line"},[_c('div',{staticClass:"line"})]),_c('div',{staticClass:"sub__menus"},[_c('div',{staticClass:"sub__menus__inner"},_vm._l((_vm.menus),function(item,index){return _c('div',{key:index,staticClass:"menu__item",class:{
                pcHover: !_vm.$bowser.mobile
              }},[_c('router-link',{staticClass:"item__link",attrs:{"to":item.link}},[_c('p',{staticClass:"link__en"},[_vm._v(_vm._s(item.title.en))]),(!_vm.$bowser.mobile)?_c('p',{staticClass:"link__cn"},[_vm._v(_vm._s(item.title.cn))]):_vm._e()])],1)}),0)])],1)]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }