/* eslint-disable global-require */
import Vue from 'vue';
import bowser from 'bowser';
import * as filters from '@/filters';
import localStorage from '@/libs/localStorage';
import baseFn from '@/utils/base';
import App from './App.vue';
import router from './router';
import store from '../store';
import '@/assets/scss/demo1.scss';

import '@/plugins/element';
import '@/plugins/wxjssdk';

Vue.config.productionTip = process.env.NODE_ENV === 'development';

Vue.prototype.$localStorage = localStorage;
Vue.prototype.$bowser = bowser;

Vue.prototype.$members = [
  {
    id: 1,
    cover: require('@/assets/images/demo1/1.png'),
    big: require('@/assets/images/demo1/member/1.jpg'),
    name: {
      en: 'zhang li',
      cn: '张力',
    },
    title: {
      cn: '创始人',
      en: 'Founder',
    },
    job: {
      cn: '设计总监',
      en: 'Design Director',
    },
    summary: '<p>多年的室内空间设计经历，优秀作品遍及各地。实现开发理念与设计构思独一无二的完美融合。</p><p><br /></p><p><br /></p><p>2021 英国International Property Awards AWARD WINNER</p><p>2021 意大利A’Design Award —— GOLD 金奖</p><p>2021 法国GPDP DEISGN AWARD FRENCN金奖</p><p>2021 美国MUSE Design Awards 金奖</p><p>2021 柏林BERLIN Design Awards银奖</p><p>2021 韩国K-DESIGN AWARD——Gold Winner</p><p><br /></p><p>2020 美国Muse Design Awards 白金奖</p><p>2020 美国Spark Design Awards 白金奖</p><p>2020 德国 iF product design award — Design Excellenc</p><p>2020 法国Novum Design Award – GOLDEN</p><p>2020 伦敦London Design Awards设计大奖金奖</p><p>2020 美国Muse Design Awards 白金奖</p><p>2020 美国Spark Design Awards 白金奖</p><p>2020 德国iF product design award — Design Excellenc</p><p>2020 法国Novum Design Award – GOLDEN</p><p>2020 伦敦London Design Awards设计大奖金奖</p><p>2020 墨尔本Melbourne Design Awards设计大奖金奖</p><p>2020 意大利A’Design Award — SILVER</p><p>2020 英国SBID International Design Awards</p><p>2020 美国MUSE Design Awards 铂金奖</p><p><br /></p><p>2019 法国双面神“GPDP AWARD”国际设计大奖金奖</p><p>2019 Paris Design Awards 巴黎设计大奖金奖</p><p>2019 美国Architecture MasterPrize 建筑大师奖</p><p>2019 香港亚太区室内设计银奖</p><p>2019 美国 Spark Design Awards 银奖</p><p>2019 意大利 A’Design Award A\'Design</p><p><br /></p><p>2018 英国 LICC Honorable mention</p><p>2018 德国红点传达设计大奖 Winner</p><p>2018 International Design Excellence Awards | SBID Awards 设计奖</p><p>2018 美国IDA Design Awards 设计奖</p><p>2018 美国星火Spark Design Awards设计大奖</p><p>2018 新加坡Society of Interior Designers 设计奖</p><p>2018 法国双面神 GPDP AWARD 国际创新设计奖</p>',
  },
  {
    id: 2,
    cover: require('@/assets/images/demo1/2.png'),
    big: require('@/assets/images/demo1/member/2.jpg'),
    name: {
      en: 'jin xing',
      cn: '金星',
    },
    title: {
      cn: '合伙人',
      en: 'Partner',
    },
    job: {
      cn: '项目总监',
      en: 'Project Director',
    },
    summary: '<p>金星先生善于把控地产、私宅、商业等项目类型的设计质量、深化落地及项目流程管理。亦致力于精装修标准化研发、设计项目协同管理平台、及BIM等多研发领域，结合多年的资深行业经验，完成了许多广泛的多样化的项目。他善遵循业主的生活追求，引导业主的生活需求，帮助业主做得更加完美，使业主生活更加舒服。</p><p><br /></p><p><br /></p><p>2020 美国MUSE Design Awards 金奖</p><p>2020 法国Novum Design Award 金奖</p><p>2020 A\'Design Award三等奖</p><p>2020 艾特奖最佳展示空间设计</p><p><br /></p><p>2019 美国Spark Design Awards 白金奖</p><p>2019 德国iF product design award — Design Excellenc</p><p>2019 伦敦London Design Awards 金奖</p><p>2019 美国Architecture MasterPrize 建筑大师奖</p><p>2019 Reka Internation design award国际设计奖</p><p>2019 Architecture MasterPrize建筑大师奖</p><p>2019 意大利A’Design Award A\'Design</p><p><br /></p><p>2018 英国LICC Honorable mention</p><p>2018 International Design Excellence Awards | SBID Awards设计奖</p><p>2018美国IDA Design Awards 设计奖</p><p>2018美国星火Spark Design Awards设计大奖</p>',
  },
  {
    id: 3,
    cover: require('@/assets/images/demo1/3.png'),
    big: require('@/assets/images/demo1/member/3.jpg'),
    name: {
      en: 'wang xinfeng',
      cn: '王新峰',
    },
    title: {
      cn: '合伙人',
      en: 'Partner',
    },
    job: {
      cn: '总经理',
      en: 'General manager',
    },
    summary: '<p>致力于精装修标准化研发、设计项目协同管理平台、及BIM等多研发领域，结合多年的资深行业经验，完成了许多广泛的多样化的项目。他善遵循业主的生活追求，引导业主的生活需求，帮助业主做得更加完美，使业主生活更加舒服。</p><p><br /></p><p><br /></p><p>2020 美国MUSE Design Awards 金奖</p><p>2020 法国Novum Design Award 金奖</p><p>2020 A\'Design Award三等奖</p><p>2020 艾特奖最佳展示空间设计</p><p><br /></p><p>2019 美国Spark Design Awards 白金奖</p><p>2019 德国iF product design award — Design Excellenc</p><p>2019 伦敦London Design Awards 金奖</p><p>2019 美国Architecture MasterPrize 建筑大师奖</p><p>2019 Reka Internation design award国际设计奖</p><p>2019 Architecture MasterPrize建筑大师奖</p><p>2019 意大利A’Design Award A\'Design</p><p><br /></p><p>2018 英国LICC Honorable mention</p><p>2018 International Design Excellence Awards | SBID Awards设计奖</p><p>2018美国IDA Design Awards 设计奖</p><p>2018美国星火Spark Design Awards设计大奖</p>',
  },
  {
    id: 4,
    cover: require('@/assets/images/demo1/4.png'),
    big: require('@/assets/images/demo1/member/4.jpg'),
    name: {
      en: 'liu yu',
      cn: '刘瑜',
    },
    title: {
      cn: '合伙人',
      en: 'Partner',
    },
    job: {
      cn: '运营总监',
      en: 'Operations director',
    },
    summary: '<p>横跨中西文化，时尚而富有个性的视角眼光，受益于西方建筑和环境，设计的多重背景知识积淀，具有着出色的设计创新思源。喜欢给每一个设计赋予不一样的灵魂，设计风格简洁、大气，注重细节、长于配色，喜欢挑战、追求创新，不断寻求设计突破。</p><p><br /></p><p><br /></p><p>绿地上海虹桥世界中心公寓样板房</p><p>南昌绿地九龙湖启动区住宅项目</p><p>昆明绿地海珀澜庭展示中心</p><p>绿地青浦赵巷海珀风华</p><p>南翔绿地威廉公馆</p><p>南昌国际博贸中心</p>',
  },
];

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

Vue.use(baseFn);

const ieVersion = Vue.prototype.IEVersion();
if (ieVersion === -1) {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
} else {
  // ie 不兼容提示
  const hintContainer = document.getElementById('browser-ie-hint-container');
  hintContainer.style.display = 'block';
  hintContainer.querySelector('.version').innerHTML = ieVersion;
}
